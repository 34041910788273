import { PropTypes } from "prop-types";
import clsx from "clsx";
import { FiLoader } from "react-icons/fi";

export const Loader = ({
  className,
  fullHeight = false,
  fullWidth = false,
  withPadding = false,
}) => {
  return (
    <div
      className={clsx(
        "Loader",
        fullHeight && "Loader--fullHeight",
        fullWidth && "Loader--fullWidth",
        withPadding && "Loader--withPadding",
        className,
      )}
      role="progressbar"
    >
      <FiLoader />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  withPadding: PropTypes.bool,
};
