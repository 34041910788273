import { createSelector } from "@reduxjs/toolkit";

const isUserLoggedIn = createSelector(
  state => state.user.isUserLoggedIn,
  isUserLoggedIn => isUserLoggedIn,
);

const getUserData = createSelector(
  state => state.user.userData,
  userData => userData,
);

const getUserPermissions = createSelector(
  state => state.user.userData?.user?.permissions,
  permissions => permissions,
);

const getBlackfinJWT = createSelector(
  state => state.user.blackfinJWT,
  blackfinJWT => blackfinJWT,
);

const getContactUsModalState = createSelector(
  state => state.user.contactUsModalOpen,
  contactUsModalOpen => contactUsModalOpen,
);

const isAdmin = createSelector(
  state => state.user.userData?.user?.permissions,
  permissions => permissions?.can_show_admin === true,
);

const getUserPlan = createSelector(
  state => state.user.userData?.organization?.formatted_plan,
  formatted_plan => formatted_plan,
);

const isUserDataFetching = createSelector(
  state => state.user.isUserDataFetching,
  isUserDataFetching => isUserDataFetching,
);

const isTosOutdated = createSelector(
  [state => state.user.isUserLoggedIn, state => state.user.userData],
  (isUserLoggedIn, userData) => isUserLoggedIn && userData?.user?.tos_outdated,
);

const hasUserBeenLoggedOutManually = createSelector(
  state => state.user.hasBeenLoggedOutManually,
  hasBeenLoggedOutManually => hasBeenLoggedOutManually,
);

const hasNewOrOneTrueCreditsEnabled = createSelector(
  [
    state => state.user.userData?.organization?.credits_or_caps,
    state => state.user.userData?.user?.permissions,
  ],
  (creditsOrCaps, permissions) =>
    creditsOrCaps === "new_credits" ||
    creditsOrCaps === "one_credit_to_rule_them_all" ||
    ["demyst_admin", "demyst_admin_org_edit", "demyst_client"].some(p => (permissions || {})[p]),
);

const lastLoggedOutPage = createSelector(
  state => state.user.lastLoggedOutPage,
  lastLoggedOutPage => lastLoggedOutPage,
);

const hasSigned = createSelector(
  state => state.user.hasSigned,
  hasSigned => hasSigned,
);

const hasProvidedBilling = createSelector(
  state => state.user.hasProvidedBilling,
  hasProvidedBilling => hasProvidedBilling,
);

const hasRequestedAgreement = createSelector(
  state => state.user.hasRequestedAgreement,
  hasRequestedAgreement => hasRequestedAgreement,
);

const hasApproval = createSelector(
  state => state.user.hasApproval,
  hasApproval => hasApproval,
);

export const userSelectors = {
  isAdmin,
  isUserLoggedIn,
  getUserData,
  isUserDataFetching,
  getUserPermissions,
  isTosOutdated,
  getBlackfinJWT,
  getContactUsModalState,
  getUserPlan,
  hasUserBeenLoggedOutManually,
  hasNewOrOneTrueCreditsEnabled,
  lastLoggedOutPage,
  hasSigned,
  hasProvidedBilling,
  hasRequestedAgreement,
  hasApproval,
};
