import { Login } from "../src/platform/views/Auth/Login";
import { NextHead } from "../src/landingPage/layouts/LayoutComponents/NextHead";
import Provider from "../src/redux/Provider";

const LoginPage = ({ initialReduxState }) => {
  return (
    <Provider.Redux initialReduxState={initialReduxState}>
      <NextHead
        currentPath="/login"
        title="Log In"
        description="Log in to your Demyst Platform account by entering your email and password."
      />
      <Login />
    </Provider.Redux>
  );
};

export default LoginPage;
