import Link from "next/link";
import { linksConstants } from "../../../../constants/linksConstants";
import { DemystLogo } from "../../../assets/svg/DemystLogo";

export const AuthPageHeader = () => {
  return (
    <header className="AuthPageHeader">
      <Link aria-label="Demyst logo" href={linksConstants.HOME}>
        <DemystLogo />
      </Link>
    </header>
  );
};
