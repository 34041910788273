import { useState, useEffect, useRef } from "react";

import { AuthPageHeader } from "./components/AuthPageHeader";
import { AuthPagePaper } from "./components/AuthPagePaper";
import { AuthPageFooter } from "./components/AuthPageFooter";
import { ThirdPartyBox } from "./components/ThirdPartyBox";
import ReCAPTCHA from "react-google-recaptcha";
import { animatePresenceConfig, appConfig } from "../../../config/appConfig";
import { AnimatePresence, motion } from "framer-motion";
import { MessageBox } from "../../components/MessageBox/MessageBox";
import { userApi } from "../../../api/userApi";
import { userActions, userThunks } from "../../../redux/user/userSlice";
import { sendGaEvent } from "../../../config/userTracking";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components/Loader/Loader";
import { userSelectors } from "../../../redux/user/userSelectors";
import { linksConstants } from "../../../constants/linksConstants";
import { serializeError } from "../../../utils/serializeError";
import { createTestId } from "../../../utils/createTestId";
import { UserUnlockedBox } from "./components/UserUnlockedBox";
import InputField from "@demystdata/ui/input_field";
import Button from "@demystdata/ui/button";
import Link from "next/link";
import { useRouter } from "next/router";
import { FiEye, FiEyeOff } from "react-icons/fi";

export const Login = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [allowsThirdPartyCookie, setAllowsThirdPartyCookie] = useState(true);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const isUserLoggedIn = useSelector(userSelectors.isUserLoggedIn);
  const isUserDataFetching = useSelector(userSelectors.isUserDataFetching);
  const lastLoggedOutPage = useSelector(userSelectors.lastLoggedOutPage);

  const [isCaptchaRequired, setIsCaptchaRequired] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(null);

  const recaptchaRef = useRef();

  const onCaptchaChange = captchaResponse => {
    setCaptchaResponse(captchaResponse);
  };

  const checkIfCaptchaIsRequired = async () => {
    try {
      const {
        data: { next_submit_requires_captcha },
      } = await userApi.isLoginCaptchaNeeded();

      if (next_submit_requires_captcha) {
        setIsCaptchaRequired(true);
      }
    } catch (e) {
      console.error(e);
      // TODO: LogRocket info?
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    const form = new FormData(event.target);
    const email = form.get("email");
    const password = form.get("password");
    const code = form.get("code");

    try {
      const { data } = await userApi.login(email, password, code, captchaResponse);

      dispatch(
        userActions.setUserData({
          userData: data,
        }),
      );
      sendGaEvent(
        {
          action: "Log In Success",
          category: "Log In",
        },
        email,
      );
    } catch (e) {
      setError(serializeError(e));
      sendGaEvent(
        {
          action: "Log In Invalid",
          category: "Log In",
        },
        email,
      );
      // if something goes wrong, set error message and send a request to API to check if CAPTCHA
      // check is needed
      await checkIfCaptchaIsRequired();
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordToggle = (e, state) => {
    e.preventDefault();
    setShowPassword(state);
  };

  useEffect(() => {
    checkIfCaptchaIsRequired();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!isUserLoggedIn && !isUserDataFetching) {
        await dispatch(userThunks.fetchUserData({ loginType: "PLATFORM" }));
      }
    };
    fetchData();

    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    const checkCookies = async () => {
      const thirdPartyCookieAllowed = await userApi.checkThirdPartyCookie();
      setAllowsThirdPartyCookie(thirdPartyCookieAllowed);
    };
    checkCookies();
  }, []);

  useEffect(() => {
    if (!isUserDataFetching) {
      document.getElementById("email")?.focus();
    }
  }, [isUserDataFetching]);

  useEffect(() => {
    if (error !== null && typeof recaptchaRef.current !== "undefined") {
      recaptchaRef.current.reset();
    }
  }, [error]);

  if (isUserLoggedIn) {
    router.push({
      pathname:
        window.location.state?.redirectTo.pathname ||
        lastLoggedOutPage ||
        linksConstants.PLATFORM_HOME,
      search: window.location.state?.redirectTo.search,
    });
  }

  if (isUserDataFetching) {
    return null;
  }

  return (
    <main role="main" className="Login">
      <div className="Login__header">
        <AuthPageHeader />
      </div>
      <div className="Login__paper">
        <AuthPagePaper>
          <header data-test-id={createTestId("loginPageHeader")} className="Login__paper__header">
            <h1 className="Login__paper__header__title">Log in to your account</h1>
          </header>
          {
            /*location.state?.userUnlocked (TODO: Researh about location state and next router)*/ false && (
              <UserUnlockedBox text="User unlocked successfully !" />
            )
          }
          {!allowsThirdPartyCookie && <ThirdPartyBox />}
          <form onSubmit={handleSubmit} className="LoginForm">
            <InputField
              size="regular"
              type="email"
              name="email"
              label="Email"
              id="email"
              autoComplete="username"
              required
              className="emailInput"
            />
            <InputField
              className={"LoginForm__password"}
              size="regular"
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              required
              rightIconSrc={
                showPassword ? (
                  <button
                    type="button"
                    className="LoginForm__toggleButton"
                    onClick={e => handlePasswordToggle(e, false)}
                  >
                    <FiEyeOff />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="LoginForm__toggleButton"
                    onClick={e => handlePasswordToggle(e, true)}
                  >
                    <FiEye />
                  </button>
                )
              }
            />
            <div className="LoginForm__forgotPasswordLink">
              <Link href={linksConstants.FORGOT_PASSWORD} data-test-id="loginForm_forgotButton">
                Forgot password?
              </Link>
            </div>
            {error && errorForMissingOrInvalid2FACode(error) && (
              <InputField
                size="regular"
                name="code"
                label="Code"
                id="code"
                autoComplete="off"
                required
              />
            )}
            {isCaptchaRequired && (
              <div className="LoginForm__captchaWrapper">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={appConfig.CAPTCHA_KEY}
                  onChange={onCaptchaChange}
                />
              </div>
            )}
            <AnimatePresence>
              {error && !errorForMissing2FACode(error) && (
                <motion.div className="LoginForm__errorMessageWrapper" {...animatePresenceConfig}>
                  <MessageBox>{error.message}</MessageBox>
                </motion.div>
              )}
            </AnimatePresence>
            <Button
              data-test-id="loginForm_submit"
              type="submit"
              size="large"
              variant="primary"
              fullWidth
            >
              Continue
            </Button>
          </form>
          <AnimatePresence>
            {loading && (
              <motion.div className="Login__paper__loadingOverlay" {...animatePresenceConfig}>
                <Loader />
              </motion.div>
            )}
          </AnimatePresence>
        </AuthPagePaper>
        <section className="Login__footer__requestPlatformLogin">
          <span>{`Don't have an account?`}</span>
          <Link href={linksConstants.CONTACT} data-test-id="loginForm_contact_link">
            Contact Us
          </Link>
          .
        </section>
      </div>
      <div className="Login__footer">
        <AuthPageFooter />
      </div>
    </main>
  );
};

function errorForMissing2FACode(error) {
  return error.status === 422 && error.message === "Two-factor authentication needed";
}

function errorForMissingOrInvalid2FACode(error) {
  return (
    errorForMissing2FACode(error) || (error.status === 422 && error.message === "Invalid code")
  );
}
