import { FiAlertTriangle } from "react-icons/fi";

export const ThirdPartyBox = () => {
  return (
    <section className="ThirdPartyBox">
      <FiAlertTriangle />
      <p>
        We've detected that your browser does not have third-party cookies enabled. The Demyst
        platform uses third-party cookies to maintain your secure session. For optimal performance,
        please{" "}
        <a
          href="https://akohubteam.medium.com/how-to-enable-third-party-cookies-on-your-browsers-f9a8143b8cc5"
          target="_blank"
          rel="noreferrer"
        >
          enable third-party cookies
        </a>{" "}
        or change browsers.
      </p>
    </section>
  );
};
